'use strict';

angular.module('informaApp')
    .service('ExportSummaryChartService', ['$http', 'ConstantsSvc', 'FileDownloader', 'OmnitureSvc', function ($http, ConstantsSvc, FileDownloader, OmnitureSvc) {
        function exportFile(path, params, filename) {
            var options = {
                url: ConstantsSvc.API.URL + path, 
                method: 'POST', 
                data: params
            };

            OmnitureSvc.export.trackChart(filename);
            return FileDownloader.downloadBinaryFile(options, filename, function () {
                console.log(`Excel download complete`);
            });
        }

        return {
            exportBarChart: function (searchData, selectedItems) {
                return exportFile('export/summary/bar-chart', {searchData, selectedItems});
            },
            exportBoxplotChart: function (searchData, selectedItems) {
                return exportFile('export/summary/boxplot-chart', {searchData, selectedItems});
            },
        };
    }]);